.content {
  background-color: #0B0B0B; }
  .content .main-title {
    margin: 6rem 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.2; }
    @media (max-width: 768px) {
      .content .main-title {
        margin: 3rem 0; } }
  .content .column-section {
    padding: 0 1rem; }
    .content .column-section .columns {
      display: flex;
      flex-flow: row wrap;
      padding-bottom: 6rem; }
      @media (max-width: 1024px) {
        .content .column-section .columns {
          flex-direction: column;
          padding-bottom: 3rem; } }
      .content .column-section .columns_item {
        flex: 1;
        margin: 0 0 1rem 0;
        font-size: 1.2rem;
        line-height: 23px;
        text-align: left; }
        .content .column-section .columns_item .highlight {
          margin-top: 0; }
        .content .column-section .columns_item:nth-of-type(2) {
          padding: 0 4rem; }
          @media (max-width: 1024px) {
            .content .column-section .columns_item:nth-of-type(2) {
              padding: 0; } }
  .content .lists-section {
    padding-bottom: 2rem; }
    .content .lists-section .lists-title {
      display: flex;
      align-items: center;
      margin: 2.3rem 0; }
      @media (max-width: 1024px) {
        .content .lists-section .lists-title {
          margin: 1.3rem 0; } }
      .content .lists-section .lists-title .title-dot {
        position: relative;
        display: inline-block;
        width: 26px;
        height: 26px;
        margin-right: 8px;
        background-color: #FFDB01;
        border-radius: 50%; }
        .content .lists-section .lists-title .title-dot:before {
          display: block;
          position: absolute;
          top: 11px;
          left: 5px;
          content: '';
          width: 16px;
          height: 4px;
          background-color: #000000; }
    .content .lists-section .lists-wrapper {
      padding: 0.8rem;
      background-color: rgba(10, 15, 20, 0.9); }
      .content .lists-section .lists-wrapper .list_title {
        margin-bottom: 0; }
      .content .lists-section .lists-wrapper .list ul {
        margin: 0;
        padding: 1rem 0;
        counter-reset: terms-cond;
        list-style: none; }
        .content .lists-section .lists-wrapper .list ul li {
          position: relative;
          margin: 1rem 0;
          padding: 0 0 0 1.5rem;
          font-size: .9rem;
          line-height: 1.5rem; }
          .content .lists-section .lists-wrapper .list ul li:before {
            color: #FFDB01;
            content: counter(terms-cond, decimal) ".";
            counter-increment: terms-cond;
            font-weight: 500;
            left: 0;
            position: absolute;
            text-align: right;
            top: 0;
            width: 1rem; }
