.header {
  background-color: #FFDB01; }
  .header_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    color: #000000; }
    .header_wrapper .logo,
    .header_wrapper .register {
      display: block;
      cursor: pointer;
      text-decoration: none;
      color: #000000; }
    .header_wrapper .register {
      display: flex;
      vertical-align: middle;
      align-items: center; }
      .header_wrapper .register:hover {
        color: #ffffff; }
      .header_wrapper .register .register-btn {
        display: none;
        width: 25px;
        height: auto;
        margin-left: 1rem; }
        @media (max-width: 414px) {
          .header_wrapper .register .register-btn {
            display: inline-block; } }

.hero {
  height: 75vh;
  overflow: hidden;
  position: relative; }
  @media (max-width: 768px) {
    .hero {
      height: 100vh;
      margin-top: 15px; } }
  .hero_text {
    position: relative;
    display: flex;
    align-items: center;
    height: inherit;
    padding: 0 25px; }
    @media (max-width: 768px) {
      .hero_text {
        justify-content: center;
        align-items: normal;
        text-align: center; } }
    .hero_text .title {
      margin: 0 0 calc(0.3rem + 0.3vw) 0;
      font-size: calc(1.1rem + 1.5vw);
      font-weight: 900;
      line-height: calc(1.5rem + 1.5vw); }
      @media (max-width: 768px) {
        .hero_text .title {
          margin: 3.5rem 0 0.7rem 0;
          font-size: calc(1.4rem + 1vw);
          line-height: calc(1rem + 4vw); } }
    .hero_text .subtitle {
      margin: 0 0 4rem 0;
      font-size: calc(1rem + 0.7vw);
      line-height: calc(0.5rem + 1.5vw); }
    @media (max-width: 768px) {
      .hero_text .button {
        display: none; } }
  .hero .bg-player,
  .hero .bg-img {
    position: absolute;
    top: 0;
    width: 102vw;
    margin-left: -1vw;
    background-position: bottom center;
    background-repeat: no-repeat; }
  .hero .bg-player {
    height: 76vh;
    background-image: url(assets/player.png);
    background-size: contain; }
    @media (max-width: 768px) {
      .hero .bg-player {
        display: none; } }
  .hero .bg-img {
    height: inherit;
    background-image: url(assets/hero-bg-img.jpeg);
    background-size: cover; }
    @media (max-width: 768px) {
      .hero .bg-img {
        width: 100%;
        margin-left: 0;
        background-image: url(assets/hero-bg-img-sm.jpeg);
        background-position: center center; } }

.hints {
  position: relative;
  display: table;
  width: 100%;
  padding: 10px 0;
  background-color: #28282E; }
  @media (max-width: 768px) {
    .hints {
      padding: 0; } }
  .hints_wrapper {
    display: flex;
    flex-flow: row wrap;
    padding: 0 1rem;
    counter-reset: steps;
    min-height: 11rem; }
    .hints_wrapper .hint_item {
      position: relative;
      display: flex;
      align-items: center;
      margin: 2rem 0;
      width: 33.33%;
      min-height: auto;
      padding: 0 35px 0 110px;
      color: #ffffff;
      font-size: 18.5px;
      font-weight: 300;
      text-align: left;
      line-height: normal;
      text-decoration: none;
      border-bottom: 0; }
      .hints_wrapper .hint_item:hover::before {
        color: #000000;
        background-color: #ffffff;
        transform: scale(1.1); }
      .hints_wrapper .hint_item::before {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 88px;
        width: 88px;
        padding: 6.5px;
        font-size: 32px;
        font-weight: 600;
        line-height: 66px;
        overflow: hidden;
        border-radius: 50%;
        border: 3.5px solid #FFDB01;
        background-clip: content-box;
        background-color: transparent;
        content: counter(steps, decimal);
        counter-increment: steps;
        transition: all .5s ease 0s; }
        @media (max-width: 1024px) {
          .hints_wrapper .hint_item::before {
            left: 0.6rem;
            top: auto; } }
      @media (max-width: 1024px) {
        .hints_wrapper .hint_item {
          width: 100%;
          min-height: 130px;
          margin: 0;
          padding: 0 15px 0 115px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          line-height: normal; } }
    .hints_wrapper .hint_text {
      font-size: 1.5rem; }
      .hints_wrapper .hint_text .highlight {
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase; }

.drawer {
  position: fixed;
  bottom: 0;
  display: inline-block;
  width: 100%;
  padding: 1rem 0;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  transition: 0.5s linear;
  z-index: 20; }
  .drawer.hidden {
    bottom: -100px; }
